import { web3Sets } from './sets'
import { NETWORK_TYPE } from '../../utils/Provider';

export class AddressSet {
  networkType: NETWORK_TYPE
  set: BscAddress | undefined

  constructor(networkType: NETWORK_TYPE) {
    this.networkType = networkType
    this.set = web3Sets[networkType]
  }
}

export class BscAddress implements SetInterface {
  haiToken: string
  etdToken: string
  tokenExchange: string

  constructor(haiToken: string, etdToken: string, tokenExchange: string) {
    this.haiToken = haiToken
    this.etdToken = etdToken
    this.tokenExchange = tokenExchange
  }
}

export interface SetInterface {
  haiToken: string
  etdToken: string
  tokenExchange: string
}
