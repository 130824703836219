import React from 'react';
import styles from './purchased.module.scss';
import MainPurchased from '../../components/sections/purchased';
import FooterPurchased from '../../components/layout/footer/FooterPurchased';

const Purchased = (): JSX.Element => {

  return (
    <div className={styles.wrapper}>
      <MainPurchased />
      <FooterPurchased />
    </div>
  );
};

export default Purchased;
