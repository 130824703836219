import React, { useContext, useEffect } from 'react';
import styles from './purchased.module.scss';
import convert from 'assets/images/purchased/convert.svg';
import transfer from 'assets/images/purchased/transfer.svg';
import book from 'assets/images/purchased/book.svg';
import { StoreContext } from '../../../utils/store';
import { useNavigate } from 'react-router-dom';
import { NumberFormatter, TokenTypes } from '../../../utils/NumberFormatter';

const MainPurchased = (): JSX.Element => {
  const {
    purchasedData: [purchasedData, setPurchasedData]
  } = useContext(StoreContext);

  const navigate = useNavigate();

  useEffect(() => {
    if(!purchasedData) {
      navigate('/', {replace: true})
    }

    return () => {
      setPurchasedData(null);
    };
  }, [purchasedData]);

  return (
    <main className={'container'}>
      <div className={styles.transactionContainer}>
        <p className={styles.status}>Transaction successful</p>
        {purchasedData !== null && <h1 className={styles.title}>You purchased {purchasedData.etd} ETD for {NumberFormatter.formatCurrency(purchasedData.hai, TokenTypes.HAI, {dp: 2})} HAI</h1>}
        <p className={styles.thanksText}>Thank you for making Web 3.0 a safer place!</p>
      </div>
      <div className={styles.blocksContainer}>
        <div className={styles.block}>
          <img src={convert} alt="Convert ETD"/>
          <h4>Convert your ETD back to HAI</h4>
          <p>If HAI value has increased, you can easily make a profit. Just convert your ETD back to the same amount of
            HAI you used for the purchase.</p>
          <p>Please note, a 10% burning fee will be applied to ETD to HAI conversion.</p>
        </div>
        <div className={styles.block}>
          <img src={book} alt="Book audit"/>
          <h4>Book your audit now and reserve a slot in priority queue</h4>
          <p>You can reserve a slot right now and we will start your project audit as soon as possible. 1 ETD guarantees
            you always have one engineering team work day for your smart contract auditing needs.</p>
          <a href={'https://hacken.io/request/'} target={'_blank'} className="btn btn-green">Book Security Audit</a>
        </div>
        <div className={styles.block}>
          <img src={transfer} alt="Transfer ETD"/>
          <h4>Transfer your ETD to other projects</h4>
          <p>Whether you have some unused ETDs left, or as part of your investment strategy, you can share your ETD with
            any projects by simply transferring them. No burning fee will be applied to ETD transfers.</p>
        </div>
      </div>
    </main>
  );
}

export default MainPurchased;
