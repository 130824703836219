import React, { lazy, Suspense } from 'react';
import styles from './transfer.module.scss';

const TransferBlock = lazy(() => import('../../components/sections/transfer/TransferBlock'));

const Transfer = (): JSX.Element => {

  return (
    <div className={styles.wrapper}>

      <Suspense fallback={<></>}>
        <TransferBlock/>
      </Suspense>
    </div>
  );
};

export default Transfer;
