import Big from 'big.js';

export enum TokenTypes {
  HAI,
  USDT,
  BNB,
  ETD
}

export const HAI_PRECISION = 1e8
export const USDT_PRECISION = 1e18


export class NumberFormatter {

  static formatCurrency(number: number | string | Big | null, type: TokenTypes, format: any = null): string {
    if(number) {
      const value = new Big(number).div(type === TokenTypes.HAI ? HAI_PRECISION : USDT_PRECISION);
      if (format) {
        return value.toFormat(format.dp, format.ts, format.ds, format.placeholder)
      } else {
        return value.toFormat();
      }
    } else {
      return '--';
    }
  }
}

export const transformAddress = (address: string) => {
  return `${address.substring(0, 12)}…${address.substring(address.length - 12)}`
}
