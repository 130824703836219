import Big from 'big.js';

Big.prototype.toFixedSpecial = function (n: number) {
  let str = this.toFixed(n)
  if (str.indexOf('e+') === -1) return str

  str = str
    .replace('.', '')
    .split('e+')
    .reduce(function (p: string, b: number) {
      return p + Array(b - p.length + 2).join('0')
    })

  if (n > 0) str += '.' + Array(n + 1).join('0')

  return str
}

Big.prototype.toFormat = function (dp = 6, ts = ',', ds = '.', placeholder = '< 0.000001') {
  if(this.eq(0)) {
    return '0';
  } else if (this.lt(0.000001)) {
    return placeholder;
  }
  let newNumber = this.toFixed(dp).split('.');

  newNumber[0] = newNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ts);
  newNumber = newNumber.join(ds);

  return newNumber;
}

declare module 'big.js' {
  interface Big {
    toFixedSpecial(n: number): string

    toFormat(dp?: number, ts?: string, ds?: string, placeholder?: string): string
  }
}
