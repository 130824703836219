import React, { useContext } from 'react';
import styles from './intro-block.module.scss';
import { StoreContext } from '../../utils/store';
import pendingIcon from '../../assets/images/icons/pending.svg';

const IntroBlock = (): JSX.Element => {
  const {connectEthereumProvider, account, pending: [pending]} = useContext(StoreContext);
  const connect = () => {
    connectEthereumProvider();
  };

  if(account) {
    return <></>;
  }

  return (
    <div className={`${styles.container} ${styles.containerConnect}`}>
      <p className={styles.limitedOffer}>*Limited OFFER*</p>
      <p className={styles.titleOffer}>FIRST 250 ETD ONLY</p>
      <p className={styles.priceUSDT}>{process.env.REACT_APP_PRICE} USDT</p>
      <p className={styles.priceHAI}>Billed in Hacken Token (HAI)</p>
      <button className={`btn btn-green ${styles.btn}`} disabled={pending} onClick={connect}>
        {pending ? <img src={pendingIcon} alt={'Wait...'} className={styles.pendingConnect} /> :'CONNECT WALLET'}
      </button>
      <p className={styles.regularPrice}>Regular price: {process.env.REACT_APP_REGULAR_PRICE} USDT</p>
    </div>
  )
};

export default IntroBlock;
