import React, { useContext } from 'react';

import styles from './footer.module.scss';
import { Store, StoreContext } from '../../../utils/store';
import { NavLink } from 'react-router-dom';
import { TRANSFER } from '../../../routes';
import pendingIcon from '../../../assets/images/icons/pending.svg';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';

const Footer = (): JSX.Element => {
  const {
    account,
    connectEthereumProvider,
    pending: [pending],
    swapBlock: [swapVisible, setSwapVisible]
  } = useContext<Store>(StoreContext);
  const {width} = useWindowDimensions();

  const scrollTo = (offset: number, callback: Function = () => {
  }) => {
    const onScroll = () => {
      if (window.pageYOffset === 0) {
        window.removeEventListener('scroll', onScroll)
        callback();
        setSwapVisible(true);
      }
    };
    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    })
  };


  const openBuyETD = () => {
    if (width > 1024) {
      scrollTo(0, () => setSwapVisible(true));
    } else {
      setSwapVisible(true);
    }
  };

  return (
    <footer className={styles.container}>
      <div className="container">
        <p className={styles.plusContainer}>
          <span className={styles.plus}></span>
          <span className={styles.plus}></span>
          <span className={styles.plus}></span>
        </p>
        <div className={styles.blocksContainer}>
          <div className={`${styles.block} ${styles.transferBlock}`}>
            <h3>Use ETD to pay for an audit. You can also transfer them to other projects.</h3>
            <p>No fees apply to ETD transfers.</p>
            <NavLink to={TRANSFER} className="btn btn-transparent">Transfer Tokens</NavLink>
          </div>
          <div className={`${styles.block} ${styles.investBlock}`}>
            <p className={styles.limited}>LIMITED OFFER! FIRST 250 ETD ONLY.</p>
            <h3>Risk-Free Investment in Your Web 3.0 Cybersecurity.</h3>
            <p>Buy ETD now for ${process.env.REACT_APP_PRICE} (Regular price ${process.env.REACT_APP_REGULAR_PRICE})</p>
            <button onClick={() => account ? openBuyETD() : connectEthereumProvider()} disabled={pending}
                    className="btn btn-green">
              {account ? 'BUY ETD Stablecoin' : pending ?
                <img src={pendingIcon} alt={'Wait...'} className={styles.pendingConnect}/> : 'CONNECT WALLET'}
            </button>
          </div>
        </div>
        <p className={styles.copyright}>Copyright © {new Date().getFullYear()}. Hacken Foundation. Privacy & Terms</p>
      </div>
    </footer>
  )
};

export default Footer;
