import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MAIN, PURCHASED, TRANSFER } from './routes';
import { Layout } from './components/layout';
import ReactModal from 'react-modal';
import Toast from './components/toast';
import Purchased from './pages/purchased';
import Main from './pages/main';
import Transfer from './pages/transfer';

const ConfirmModal = lazy(() => import('./components/modal/ConfirmModal'));
const NotInstalledModal = lazy(() => import('./components/modal/NotInstalledModal'));

ReactModal.setAppElement('#root');

const App = (): JSX.Element => {

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path={MAIN} element={<Main/>}/>
          <Route path={PURCHASED} element={<Purchased/>}/>
          <Route path={TRANSFER} element={<Transfer/>}/>
        </Routes>
      </Layout>
      <Toast/>
      <Suspense fallback={''}>
        <ConfirmModal/>
      </Suspense>
      <Suspense fallback={''}>
        <NotInstalledModal/>
      </Suspense>
    </Router>
  )
}

export default App
