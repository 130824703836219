import React from 'react';
import styles from './footer-purchased.module.scss';
const FooterPurchased = (): JSX.Element => {


  return (
    <footer className={styles.footer}>
      <div className="container">
        <p className={styles.contacts}>
          Contact your personal Hacken manager for immediate support.
        </p>
      </div>
    </footer>
  );
};

export default FooterPurchased;
