import React from 'react';
import styles from './about.module.scss';
import dao from 'assets/images/about/dao.svg';
import icon_1inch from 'assets/images/about/1inch.svg';
import hapi from 'assets/images/about/hapi.svg';
import chainlink from 'assets/images/about/chainlink.svg';
import vechain from 'assets/images/about/vechain.svg';
import kyberNetwork from 'assets/images/about/kybernetwork.svg';
import etherscan from 'assets/images/about/etherscan.svg';
import coinmarketcap from 'assets/images/about/coinmarketcap.svg';
import coingecko from 'assets/images/about/coingecko.svg';
import crystal from 'assets/images/about/crystal.svg';
import liquid from 'assets/images/about/liquid.svg';
import avaLabs from 'assets/images/about/ava-labs.svg';
import simplex from 'assets/images/about/simplex.svg';
import polkastarter from 'assets/images/about/polkastarter.svg';

const Index = (): JSX.Element => {

  return (
    <section className={'container'}>
      <div className={styles.container}>
        <div className={styles.containerInfo}>
          <div className={styles.aboutContainer}>
            <h2>What is Hacken?</h2>
            <p>Hacken is a fully-fledged cybersecurity ecosystem founded in August 2017 by cybersecurity experts, Big
              Four professionals, and white hat hackers.</p>
            <p>Hacken provides cybersecurity services to all types of Web 3.0 clients including crypto exchanges, DeFi
              projects, and NFT ecosystems.</p>
            <a className={`btn ${styles.learnMore}`} target={'_blank'} href={'https://bit.ly/HACKEN_cybersecurity'}>LEARN
              MORE</a>
          </div>
          <div className={styles.listContainer}>
            <ul className={styles.aboutList}>
              <li>
                <p><strong><i>800</i> clients</strong></p>
              </li>
              <li>
                <p><strong><i>80</i> partners</strong></p>
              </li>
              <li>
                <p><strong><i>23/50</i> top exchanges</strong>Hacken Clients</p>
              </li>
              <li>
                <p><strong><i>6%</i> share</strong>Web 3.0 cybersecurity market</p>
              </li>
            </ul>
          </div>
        </div>
        <ul className={styles.companiesList}>
          <li>
            <a href="" target={'_blank'}>
              <img src={dao} alt="DaoMarket"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={avaLabs} alt="Ava Labs"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={icon_1inch} alt="1inch"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={liquid} alt="Liquid"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={kyberNetwork} alt="Kyber Network"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={polkastarter} alt="Polkastarter"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={vechain} alt="VeChain"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={etherscan} alt="Etherscan"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={crystal} alt="Crystal"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={coingecko} alt="CoinGecko"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={coinmarketcap} alt="CoinMarketCap"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={simplex} alt="Simplex"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={chainlink} alt="Chainlink"/>
            </a>
          </li>
          <li>
            <a href="" target={'_blank'}>
              <img src={hapi} alt="HAPI"/>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Index;
