import React, { Suspense, lazy } from 'react';
import { ReactComponent as Logo } from 'assets/images/header/hackenfoundation-logo.svg';
import styles from './header.module.scss';
import { NavLink } from 'react-router-dom';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import { MAIN, TRANSFER } from '../../../routes';

const ConnectBlock = lazy(() => import('../../connect-block'));

const Header = (): JSX.Element => {
  const {width} = useWindowDimensions();

  return (
    <header className={'container ' + styles.header}>
      <a href={'https://hackenfoundation.com/'} className={styles.logo}>
        <Logo/>
      </a>
      <nav className={styles.navContainer}>
        <NavLink to={MAIN}>Buy{width > 600 && ' ETD'}</NavLink>
        <NavLink to={TRANSFER}>Transfer{width > 600 && ' ETD'}</NavLink>
      </nav>
      <Suspense fallback={<></>}>
        <ConnectBlock/>
      </Suspense>
    </header>
  );
};

export default Header;
