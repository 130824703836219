import React from 'react';
import Intro from '../../components/sections/intro';
import About from '../../components/sections/about';
import Footer from '../../components/layout/footer';

const Main = (): JSX.Element => {

  return (
    <>
      <main>
        <Intro/>
        <About/>
      </main>
      <Footer/>
    </>
  )
};

export default Main;
