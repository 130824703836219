import React, { createContext, useEffect, useMemo, useState } from 'react';
import { abiErc20, abiETD } from '../contracts/web3';
import Big from 'big.js';
import { MaxUint256 } from '@ethersproject/constants';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { AbstractProvider as Provider } from 'web3-core';
import {
  ETD_APPROVE_KEY,
  HAI_APPROVE_KEY, initialWallet,
  Networks,
  ProviderType,
  SendParams,
  Wallet,
  WALLET_STATUS_KEY,
  WalletStatus,
  web3Provider
} from './Provider';
import { toast } from 'react-hot-toast';
import { Abi_EngineerTeamDay, Abi_ERC20 } from '../contracts/web3/typings';

export interface Store {
  account: string | null,
  accounts: string[],
  isWalletNetwork: boolean | null,
  wallet: Wallet,
  userChainId: number | null,
  notInstalledModal: [boolean, Function],
  disconnectEthereumProvider: Function,
  connectEthereumProvider: Function,
  confirmData: [any, Function],
  swapBlock: [boolean, Function],
  pending: [boolean, Function],
  haiAllowance: Big,
  isEtdApproved: boolean,
  purchasedData: [any, Function],
  approveHAI: Function,
  approveETD: Function,
  getGasPrice: Function,
  calcSendParams: Function
}

export const StoreContext = createContext<any>(null)

export const StoreProvider = ({children}: any) => {
  const [wallet, setWallet] = useState<Wallet>(initialWallet);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [userChainId, setUserChainId] = useState<number | null>(null);
  const [swapVisible, setSwapVisible] = useState<boolean>(false);
  const [notInstalledModal, setNotInstalledModal] = useState<boolean>(false);
  const [haiAllowance, setHaiAllowance] = useState<Big>(new Big(0));
  const [pending, setPending] = useState<boolean>(false);
  const [isEtdApproved, setEtdApproved] = useState<boolean>(false);

  const [confirmData, setConfirmData] = useState<any>(null);
  const [purchasedData, setPurchasedData] = useState<any>(null);


  const connectEthereumProvider = async (): Promise<boolean> => {
    let connected = false;
    setPending(true);
    let provider = await detectEthereumProvider();
    // if (!provider) {
    //   provider = window.ethereum;
    // }
    // @ts-ignore
    if (provider?.isMetaMask) {
      // @ts-ignore
      const web3 = new web3Provider(provider as Provider);
      try {
        const accounts = await web3.eth.requestAccounts();
        if (accounts.length > 0) {
          // @ts-ignore
          const type = provider.isMetaMask ? ProviderType.metamask : ProviderType.browser;
          await setWalletData(type, accounts, provider as Provider, web3);
          localStorage.setItem(WALLET_STATUS_KEY, WalletStatus.connected);
          connected = true;
        }
      } catch (e: any) {
        console.error('connectEthereumProvider error', e.message);
      }
    } else {
      setNotInstalledModal(true);
    }

    setPending(false);
    return connected;
  };
  const checkConnectedWallet = async (): Promise<boolean> => {
    const walletStatus = localStorage.getItem(WALLET_STATUS_KEY);
    let connected = false;
    let type: string | null = null;

    let provider = await detectEthereumProvider();
    // if (!provider) {
    //   provider = window.ethereum;
    // }

    // @ts-ignore
    if (provider?.isMetaMask) {
      // store.dispatch(changeMetamaskInstalled(true));
      if (walletStatus == WalletStatus.connected) {
        const haiAllowedLocal = localStorage.getItem(HAI_APPROVE_KEY);
        const etdAllowedLocal = localStorage.getItem(ETD_APPROVE_KEY);
        setHaiAllowance(haiAllowedLocal ? new Big(JSON.parse(haiAllowedLocal)) : haiAllowance);
        setEtdApproved(etdAllowedLocal ? JSON.parse(etdAllowedLocal) : isEtdApproved);
        const web3 = new web3Provider(provider as Provider);
        try {
          const accounts = await web3.eth.getAccounts();
          if (accounts.length > 0) {
            // @ts-ignore
            type = provider.isMetaMask ? ProviderType.metamask : ProviderType.browser;
            await setWalletData(type, accounts, provider as Provider, web3);
            connected = true;
          }
        } catch (e: any) {
          console.error(`${type} provider error`, e.message);
        }
      }
    } else {
      // setNotInstalledModal(true);
    }

    // store.dispatch(changeWalletConnectionChecked(true));
    return connected;
  };
  const getChainId = async (web3: Web3): Promise<number | null> => {
    let chainId: number | null = null;

    if (web3) {
      try {
        chainId = await web3.eth.getChainId();
      } catch (e: any) {
        console.error('getChainId error: ', e);
      }
    }

    return chainId;
  };

  const switchNetwork = async (): Promise<boolean> => {
    let result: boolean = false;

    if (wallet.provider && wallet.web3) {
      const {provider, web3}: Wallet = wallet;
      try {
        // @ts-ignore
        await provider.request({
          method: 'wallet_switchEthereumChain',
          // @ts-ignore
          params: [{chainId: chainIdToHexString(Networks[web3.currentNetwork].chainId)}],
        });
        toast.dismiss();
        result = true;
      } catch (switchError) {
        try {
          // @ts-ignore
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              // @ts-ignore
              ...Networks[web3.currentNetwork],
              // @ts-ignore
              chainId: chainIdToHexString(Networks[web3.currentNetwork].chainId)
            }],
          });
          // @ts-ignore
          await provider.request({
            method: 'wallet_switchEthereumChain',
            // @ts-ignore
            params: [{chainId: chainIdToHexString(Networks[web3.currentNetwork].chainId)}],
          });

          toast.dismiss();
        } catch (addError) {
          console.error('switchNetwork addError: ', addError);
        }

        console.error('switchNetwork switchError: ', switchError);
      }
    }

    return result;
  }
  const chainIdToHexString = (chainId: number): string => {
    return `0x${chainId.toString(16)}`;
  }
  const trackProviderEvents = (provider: any): void => {
    if (provider != null) {
      provider.on('accountsChanged', (accounts: string[]) => {
        // console.log('Provider accounts changed to', accounts);
        setAccounts(accounts);

        if (accounts.length == 0) {
          // console.log('Provider disconnected');
          setUserChainId(null);
        }
      });

      provider.on('chainChanged', (chainId: number | string) => {
        // console.log('Provider chain id changed to', chainId);
        const chainIdNumber = parseInt(`${chainId}`, 16);
        setUserChainId(chainIdNumber);
      });

      provider.on('disconnect', () => {
        // console.log('Provider disconnected');
        resetWalletData();
        setUserChainId(null);
      });
    }
  }
  const disconnectEthereumProvider = async (): Promise<void> => {
    resetWalletData();
    localStorage.setItem(WALLET_STATUS_KEY, WalletStatus.disconnected);
  }

  const resetWalletData = (): void => {
    setWallet(initialWallet);
    setAccounts([]);
  };

  const setWalletData = async (type: string | null, accounts: string[], provider: Provider, web3: web3Provider): Promise<void> => {
    setWallet({
      type,
      provider,
      web3
    });
    setAccounts(accounts);
    setUserChainId(await getChainId(web3))
    trackProviderEvents(provider);
  }

  const getAllowanceHAI = async () => {
    if (account && wallet.web3) {
      const {web3} = wallet;
      // @ts-ignore
      const contractHAI = (new web3.eth.Contract(abiErc20, web3.contractSet.haiToken) as any) as Abi_ERC20;
      const result = await contractHAI.methods
        // @ts-ignore
        .allowance(account, web3.contractSet.tokenExchange)
        .call()
        .catch((e) => {
          throw new Error(`allowance failed ${e.message}`)
        });

      const bigVal = new Big(result);

      if(!haiAllowance.eq(bigVal)) {
        localStorage.setItem(HAI_APPROVE_KEY, result);
        setHaiAllowance(bigVal);
      }
    }
  };
  const getAllowanceETD = async () => {
    if (account && wallet.web3) {
      const {web3} = wallet;
      // @ts-ignore
      const contractETD = (new web3.eth.Contract(abiETD, web3.contractSet.etdToken) as any) as Abi_EngineerTeamDay

      const result = await contractETD.methods
        // @ts-ignore
        .isApprovedForAll(account, web3.contractSet.tokenExchange)
        .call()
        .catch((e) => {
          throw new Error(`allowance failed ${e.message}`)
        });

      if(result !== isEtdApproved) {
        localStorage.setItem(ETD_APPROVE_KEY, JSON.stringify(result));
        setEtdApproved(result);
      }
    }
  };

  const updateAllowance = async () => {
    getAllowanceETD().then();
    getAllowanceHAI().then();
  };

  const approveHAI = async () => {
    if (wallet.web3 && account) {
      const {web3} = wallet;
      setPending(true);
      // @ts-ignore
      const contractHAI = (new web3.eth.Contract(abiErc20, web3.contractSet.haiToken) as any) as Abi_ERC20;
      // @ts-ignore
      const result = await contractHAI.methods.approve(web3.contractSet.tokenExchange, MaxUint256)
        .send({from: account})
        .catch(e => {
          if(e.code === 4001) {
            toast.error('Transaction cancelled');
          }
          setPending(false);
          throw new Error(`Approve failed ${e.message}`);
        });

      setPending(false);
      localStorage.setItem(HAI_APPROVE_KEY, MaxUint256.toString())
      setHaiAllowance(new Big(MaxUint256.toString()))
    }
  };
  const approveETD = async () => {
    if (wallet.web3 && account) {
      const {web3} = wallet;
      setPending(true);
      // @ts-ignore
      const contractETD = (new web3.eth.Contract(abiETD, web3.contractSet.etdToken) as any) as Abi_EngineerTeamDay;
      const approved = await contractETD.methods
        // @ts-ignore
        .setApprovalForAll(web3.contractSet.tokenExchange, true)
        .send({from: account})
        .catch(e => {
          if(e.code === 4001) {
            toast.error('Transaction cancelled');
          }
          setPending(false);
          setEtdApproved(false);
          throw new Error(`Approve failed ${e.message}`);
        });

      setPending(false);
      localStorage.setItem(ETD_APPROVE_KEY, JSON.stringify(true));
      setEtdApproved(true);
    }
  };

  const calcSendParams = async (estimatedGas: number, value?: Big): Promise<SendParams> => {
    let result: SendParams = null;

    if (wallet.web3 && account) {
      try {
        const gas = Math.ceil(estimatedGas);
        let gasPrice = await getGasPrice();
        if (gasPrice != null) {
          gasPrice = gasPrice.times(1.5);
        }

        result = {
          from: account,
          gas
        }

        if (gasPrice != null) {
          result.gasPrice = gasPrice.toFixed(0);
        }

        if (typeof value != 'undefined' && value != null) {
          result.value = value.toFixed(0);
        }
      } catch (e) {
        console.error('getGasPrice error', e);
      }
    }

    return result;
  }

  const getGasPrice = async (): Promise<Big | null> => {
    let result: Big | null = null;

    if (wallet.web3) {
      try {
        result = new Big(await wallet.web3.eth.getGasPrice());
      } catch (e: any) {
        console.error('Error getGasPrice:', e);
      }
    }

    return result;
  }

  const account = useMemo(() => {
    return accounts.length > 0 ? accounts[0] : null;
  }, [accounts]);
  const isWalletNetwork = useMemo<boolean | null>(() => {
    return wallet.web3 && userChainId === Networks[wallet.web3.currentNetwork].chainId;
  }, [userChainId, wallet]);

  useEffect(() => {
    if (wallet.web3 && account) {
      // @ts-ignore
      const currentNetwork = Networks[wallet.web3.currentNetwork];

      if (currentNetwork.chainId !== userChainId) {
        toast.loading(<p>
          Wallet chain id {userChainId} doesn't match selected {currentNetwork.chainName} chain
          id {currentNetwork.chainName}, please <button onClick={switchNetwork}>change</button> it.
        </p>)
      } else {
        toast.dismiss();
      }
    }
  }, [userChainId]);
  useEffect(() => {
    if (account && isWalletNetwork) {
      updateAllowance().then();
    }
  }, [account, isWalletNetwork]);
  useEffect(() => {
    (async () => {
      await checkConnectedWallet();
    })();
  }, []);

  const store: Store = {
    account,
    accounts,
    isWalletNetwork,
    wallet,
    userChainId,
    notInstalledModal: [notInstalledModal, setNotInstalledModal],
    disconnectEthereumProvider,
    connectEthereumProvider,
    confirmData: [confirmData, setConfirmData],
    swapBlock: [swapVisible, setSwapVisible],
    pending: [pending, setPending],
    haiAllowance,
    isEtdApproved,
    purchasedData: [purchasedData, setPurchasedData],
    approveHAI,
    approveETD,
    getGasPrice,
    calcSendParams
  }

  return <StoreContext.Provider value={store}>{store ? children : null}</StoreContext.Provider>
}
