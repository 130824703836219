import { AddressSet } from '../contracts/sets'
import  Web3 from 'web3'
import { AbstractProvider, provider } from 'web3-core'

const BINANCE_MAINNET_PARAMS = {
  chainId: 56,
  chainName: 'Binance Smart Chain',
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    decimals: 18
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com/']
}
const BINANCE_TESTNET_PARAMS = {
  chainId: 97,
  chainName: 'Binance Testnet',
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    decimals: 18
  },
  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  blockExplorerUrls: ['https://testnet.bscscan.com']
};

export type Wallet = {
  type: string | null
  provider: AbstractProvider | null
  web3: web3Provider | null
}

export const initialWallet: Wallet = {
  type: null,
  provider: null,
  web3: null
}
export type SendParams = {
  from: string
  gas: number
  gasPrice?: string
  value?: string
} | null
export enum WalletStatus {
  connected = 'connected',
  disconnected = 'disconnected'
}


export enum ProviderType {
  metamask = 'MetaMask',
  browser = 'browser'
}

export enum NETWORK_TYPE {
  Binance = 'Binance',
}

export const Networks = {
  [NETWORK_TYPE.Binance]: process.env.REACT_APP_NETWORK === 'mainnet' ? BINANCE_MAINNET_PARAMS : BINANCE_TESTNET_PARAMS
}

export const WALLET_STATUS_KEY = 'WALLET_STATUS_KEY';
export const ETD_APPROVE_KEY = 'ETD_APPROVE_KEY';
export const HAI_APPROVE_KEY = 'HAI_APPROVE_KEY';

export type ETDParams = {
  price: string,
  deadline: string,
  signature: string
} | null;

export class web3Provider extends Web3 {
  constructor(connector: provider) {
    super(connector)
    this.currentNetwork = NETWORK_TYPE.Binance
  }

  currentNetwork: NETWORK_TYPE

  set setCurrentNetworkType(networkType: NETWORK_TYPE) {
    this.currentNetwork = networkType
  }

  get contractSet() {
    return new AddressSet(this.currentNetwork).set
  }
}
