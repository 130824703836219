import { SetInterface } from './index'
import { NETWORK_TYPE } from '../../utils/Provider';

export const web3Sets: { [key in NETWORK_TYPE]?: SetInterface } = {
  Binance: {
    haiToken: <string>process.env.REACT_APP_CONTRACT_HAI,
    etdToken: <string>process.env.REACT_APP_CONTRACT_ETD,
    tokenExchange: <string>process.env.REACT_APP_CONTRACT_TOKEN_EXCHANGE
  }
}
