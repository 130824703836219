import React from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { ReactComponent as Close } from 'assets/images/icons/close.svg';

const Toast = (): JSX.Element => {

  return (
    <Toaster containerClassName={'toast-container'} toastOptions={{
      className: 'toast',
      success: {
        className: 'toast toast-success'
      },
      error: {
        className: 'toast toast-error'
      },
      loading:{
        className: 'toast toast-loading'
      }
    }}>
      {(t) => (
        <ToastBar toast={t} position={'top-center'}>
          {({icon, message}) => (
            <>
              {message}
              {t.type !== 'loading' && (
                <button className={'toast-close'} onClick={() => toast.dismiss(t.id)}>
                  <Close />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;
