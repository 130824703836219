import React from 'react';
import { ReactComponent as ETDLogo } from 'assets/images/intro/etd-title.svg';
import { ReactComponent as BG } from 'assets/images/intro/bg.svg';

import styles from './intro.module.scss';
import IntroBlock from '../../connect-block/IntroBlock';

const Index = (): JSX.Element => {

  return (
    <section className={`container ${styles.container}`}>
      <BG className={styles.bgImage}/>
      <div className={styles.infoContainer}>
        <ETDLogo className={styles.title}/>
        <p className={styles.description}>A new type of risk-free investment in your cybersecurity by Hacken</p>
      </div>
      <IntroBlock/>
      <p className={styles.plusContainer}>
        <span className={styles.plus}></span>
        <span className={styles.plus}></span>
        <span className={styles.plus}></span>
      </p>
      <p className={styles.etdInfo}><strong>ETD</strong> is Engineering Team Day</p>
      <ul className={styles.ratingList}>
        <li>
          <span className={styles.ratingDay}>1 day</span>
          <span className={styles.ratingName}>Senior Auditor</span>
        </li>
        <li>
          <span className={styles.ratingDay}>1 day</span>
          <span className={styles.ratingName}>Middle Auditor</span>
        </li>
        <li>
          <span className={styles.ratingDay}>0.5 day</span>
          <span className={styles.ratingName}>Security QA Lead</span>
        </li>
        <li>
          <span className={styles.ratingDay}>0.5 day</span>
          <span className={styles.ratingName}>Reporting Expert</span>
        </li>
      </ul>
      <ul className={styles.etdInfoList}>
        <li>
          <strong>Pay for a smart contract audit</strong> with Engineering Team Day Stablecoin. Forget about delays in product
          launch with our guaranteed priority queue for ETD holders. ETD is offered at a discounted rate.
        </li>
        <li>
          <strong>Transfer ETDs</strong> to your portfolio or partnering projects as a part of your investment. Make
          sure you and your partners are protected by a leading Web 3.0 cybersecurity auditor with {'>'}4 years
          expertise.
        </li>
        <li><strong>ETD is a Risk-Free Asset:</strong> ETD price is locked in HAI at purchase. You can benefit from HAI
          growth by swapping ETD back to HAI (10% burning commission applies). Otherwise, you still have 1 ETD in full.
        </li>

      </ul>
    </section>
  )
};

export default Index;
