import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import './utils/declareBig';
import {StoreProvider} from './utils/store';

ReactDOM.render(
  <StoreProvider>
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  </StoreProvider>,
  document.getElementById('root')
)
